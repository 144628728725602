import { BUYPLAN_COMPLETED_STEPS, BUYPLAN_VALUES, ONBOARDING_COMPLETED_STEPS, ONBOARDING_VALUES } from '../../utils/constants';
import { Box, BoxProps, Button, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, Icon } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { RxHamburgerMenu } from 'react-icons/rx';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Script from 'next/script';
import SideNav from './side-nav';
import { cookieStorage } from '../../utils/CookieStore';
import { isUK } from '../../utils/partnerTheme';
import { useAuth } from '../../contexts/AuthContext';
import { useRouter } from 'next/router';
export interface Link {
  link: string;
  label: string;
  icon: string;
  url: string;
  size: string;
}
export const authNavLinks = {
  home: {
    icon: 'Home',
    size: '1.5rem',
    url: '/account/home',
    label: 'Dashboard'
  },
  transactions: {
    icon: 'Transactions',
    size: '1.5rem',
    url: '/account/transactions',
    label: 'Transactions'
  },
  my_plans: {
    icon: 'My Plans',
    size: '1.5rem',
    url: '/account/device-care/plans',
    label: 'My Plans'
  },
  message: {
    icon: 'Message',
    url: null,
    label: 'Message',
    size: '1.5rem'
  },
  notifications: {
    icon: 'Notifications',
    url: '/account/notifications',
    label: 'Notifications',
    size: '1.5rem'
  }
};
export const anonymousNavLinks = {
  'request-fix': {
    icon: 'Request Fix',
    url: '/request-fix/category',
    label: 'Request Fix',
    size: '1.5rem'
  },
  ...(!isUK ? {
    'device-protect': {
      icon: 'Device Protection',
      url: '/device-care/plan',
      label: 'Protect Your Device',
      size: '1.5rem'
    }
  } : {})
};
const DashBoardLayout = ({
  children
}: BoxProps) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const {
    isLoggedIn
  } = useAuth() as {
    isLoggedIn: boolean;
  };
  const handleResize = () => {
    if (window.innerWidth >= 768 && isOpen) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, isOpen]);
  useEffect(() => {
    if (!router.pathname.includes('onboarding')) {
      cookieStorage.removeItem(ONBOARDING_VALUES);
      cookieStorage.removeItem(ONBOARDING_COMPLETED_STEPS);
    }
    if (!router.pathname.includes('/plan')) {
      cookieStorage.removeItem(BUYPLAN_COMPLETED_STEPS);
      cookieStorage.removeItem(BUYPLAN_VALUES);
    }
  }, [router.pathname]);
  return <>
      <Flex data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <Box display={{
        md: 'none'
      }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <Button onClick={() => setIsOpen(!isOpen)} pos="absolute" zIndex="100000" right={0} top="15px" bgColor="transparent" _active={{
          bg: 'transparent',
          outline: 'none'
        }} _hover={{
          bg: 'transparent',
          outline: 'none'
        }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            <Icon as={RxHamburgerMenu} bg="transparent" color={isOpen ? 'white' : '#0F61D6'} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
          </Button>
          <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)} data-sentry-element="Drawer" data-sentry-source-file="index.tsx">
            <DrawerOverlay data-sentry-element="DrawerOverlay" data-sentry-source-file="index.tsx" />
            <DrawerContent w="280px" data-sentry-element="DrawerContent" data-sentry-source-file="index.tsx">
              <DrawerBody data-sentry-element="DrawerBody" data-sentry-source-file="index.tsx">
                <SideNav links={isLoggedIn ? Object.values(authNavLinks) as Link[] : Object.values(anonymousNavLinks) as Link[]} data-sentry-element="SideNav" data-sentry-source-file="index.tsx" />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>

        <Box display={{
        base: 'none',
        md: 'block'
      }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <SideNav links={isLoggedIn ? Object.values(authNavLinks) as Link[] : Object.values(anonymousNavLinks) as Link[]} data-sentry-element="SideNav" data-sentry-source-file="index.tsx" />
        </Box>

        <Box flex={1} ml={{
        md: '280px'
      }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <Box position="relative" data-sentry-element="Box" data-sentry-source-file="index.tsx">
            <Box
          // {...rest}
          width={{
            base: '100%',
            md: 'calc(100vw - 280px)'
          }} as="main" mx="auto" height="100vh" position="fixed" overflowY="auto" backgroundColor="white" pb={74} data-sentry-element="Box" data-sentry-source-file="index.tsx">
              <AnimatePresence mode="wait" initial={false} onExitComplete={() => window.scrollTo(0, 0)} data-sentry-element="AnimatePresence" data-sentry-source-file="index.tsx">
                {children}
              </AnimatePresence>
            </Box>
          </Box>
        </Box>
      </Flex>
      {/* <Script
        id="simpu-widget"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
       var widget = window.Simpu.default.init({
        app_id: "${process.env.NEXT_PUBLIC_SIMPU_APP_ID}",
        public_key: "spk__OnLYp2ch7xGJ85qiurGpJ0AkYXuMSxZIruCYrbRC2MN5BSOwXb"
       });
       widget.render({
          name: "${user?.first_name} ${user.last_name}",
          email: "${user.email}",
          user_id: "${user.id}",
       });
       `,
        }}
       /> */}
    </>;
};
export default DashBoardLayout;